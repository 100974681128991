require ("bootstrap/dist/js/bootstrap.min.js")
require ("@popperjs/core/dist/umd/popper.min.js")
require ('./src/components/prism.css')

const React = require("react")
const Layout = require("./src/components/layout").default
const PageWrapper = require ("./src/components/pageWrapper.jsx").default

exports.wrapPageElement = ({ element, props }) => {
    return (
        <Layout><PageWrapper {...props}>{element}</PageWrapper></Layout>
    )
}
