import React from 'react';
import loadable from '@loadable/component';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';

const ExchangeWidget = loadable(() =>
  import(/* webpackPrefetch: true */ "./exchangeWidget"),
    {
        fallback:
        <div className="d-flex justify-content-center" style={{alignItems: "center", height: "680px", marginBottom: "240px"}}>
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>

    }
)

const TickerTape = loadable (() => import (/* webpackPrefetch: true */ './tickerTape'))

const PageWrapper = ({ children }) => {

    return (
      <React.Fragment>
        {children}
        <div hidden={!children.props.location.pathname.includes ('/exchange/')}>
            <TickerTape />
            <ExchangeWidget />
        </div>
      </React.Fragment>
    )

};

export default PageWrapper;
