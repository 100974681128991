module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#2a7fff","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"isIconAfterHeader":true,"icon":"<svg aria-hidden=\"true\" width=\"24px\" height=\"24px\" fill=\"none\" version=\"1.1\" viewBox=\"0 0 24 24\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m9.1649 17.65c-0.23975 0.2238-0.4253 0.3736-0.61492 0.4831-0.92821 0.5359-2.0718 0.5359-3 0-0.34205-0.1975-0.67084-0.5263-1.3284-1.1838-0.65759-0.6576-0.98639-0.9864-1.1839-1.3285-0.5359-0.9282-0.5359-2.0718 0-3 0.19747-0.342 0.52627-0.6708 1.1839-1.3284l2.8284-2.8284c0.65759-0.65759 0.98638-0.98639 1.3284-1.1839 0.92821-0.5359 2.0718-0.5359 3-1e-5 0.342 0.19748 0.6708 0.52628 1.3284 1.1839s0.9864 0.98638 1.1839 1.3284c0.5359 0.9282 0.5359 2.0718 0 3-0.1095 0.1896-0.2593 0.3752-0.4832 0.6149m-2.8156-2.8156c-0.2239 0.2397-0.3737 0.4253-0.4832 0.6149-0.53586 0.9282-0.53586 2.0718 0 3 0.1975 0.3421 0.5263 0.6709 1.1839 1.3284 0.6576 0.6576 0.9864 0.9864 1.3284 1.1839 0.9282 0.5359 2.0718 0.5359 3 0 0.3421-0.1975 0.6709-0.5263 1.3285-1.1839l2.8284-2.8284c0.6576-0.6576 0.9864-0.9864 1.1838-1.3284 0.5359-0.9282 0.5359-2.0718 0-3-0.1974-0.34204-0.5262-0.67084-1.1838-1.3284s-0.9864-0.98638-1.3284-1.1839c-0.9283-0.5359-2.0718-0.5359-3 0-0.1897 0.10948-0.3752 0.25931-0.615 0.48315\" stroke=\"var(--bs-body-color)\" stroke-linecap=\"round\" stroke-width=\"2\"/></svg>","offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":630,"linkImagesToOriginal":false,"quality":100,"backgroundColor":"none","showCaptions":false,"markdownCaptions":false,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/eagle-gray-40.png","theme_color":"#0055d4","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"44683e061a0b8b6a7387fe19767f4308"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
