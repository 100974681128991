import * as React from "react";
import { Link } from "gatsby";
import Navbar from 'react-bootstrap/Navbar';
import Nav from "react-bootstrap/Nav"
import Container from 'react-bootstrap/Container'
import NavbarToggle from 'react-bootstrap/NavbarToggle'
import NavbarCollapse from 'react-bootstrap/NavbarCollapse'
import NavLink from 'react-bootstrap/NavLink'

import logo from "../images/wings-logo-angled.svg"

const isActive = ({ isCurrent }) => {
  return isCurrent ? { className: "nav-link active" } : {className: "nav-link"}
}

const ExactNavLink = props => (
  <Link getProps={isActive} {...props} />
)

const CustomNavbar = () => {
  return (
    <Navbar expand="sm" sticky={"top"} bg={"body-tertiary"} className="shadow-sm" collapseOnSelect>
      <Container fluid={true}>
        <Link to="/" className="navbar-brand" href="#">
          <img src={logo} width={72} quality={12} alt="Logo" className="pe-3 ps-2"/>
          {"Corya.US"}
        </Link>
        <NavbarToggle aria-controls="basic-navbar-nav"></NavbarToggle>

        <NavbarCollapse>
          <Nav className="me-auto mb-0 text-end">
            <NavLink as={ExactNavLink} to="/" href="#">Home</NavLink>
            <NavLink as={ExactNavLink} to="/exchange" href="#">Exchange</NavLink>
            <NavLink as={ExactNavLink} to="/blog" href="#">Blog</NavLink>
          </Nav>
          {/*<a href="default.asp"><StaticImage src="../images/storj.svg" height={24} width={24} alt="HTML tutorial"></StaticImage></a>*/}

        </NavbarCollapse>
      </Container>
    </Navbar>
  )
}

export default CustomNavbar
