import React from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

import { ReactComponent as FooterEagle } from '../images/footer-eagle.svg'

import { ReactComponent as TwitterIcon } from '../images/twitter.svg'
import { ReactComponent as SteemitIcon } from '../images/steemit.svg'
import { ReactComponent as MediumIcon } from '../images/medium.svg'
import { ReactComponent as HiveIcon } from '../images/hive.svg'

const Footer = () =>
  <footer className="page-footer font-small">
    <Container fluid={true} className="container-fluid border-top pt-4">
        <style type="text/css">
            {`
                .footer-img > path {
                    fill: var(--bs-body-color) !important;
                }
            `}
        </style>
        <Row>
            <Col md={3} xs={6} className="text-start d-flex justify-content-center order-2 order-md-1" style={{alignItems: "center"}}>
                <FooterEagle className="footer-img" viewBox="-400 0 1600 1600" height="256px" style={{marginBottom: "-100px"}}/>
            </Col>
            <Col md={6} className="mt-md-0 my-3 text-start order-1">
                <h5>Corya.US</h5>
                <p>Corya.US is the public-facing blockchain and cryptocurrency division of Corya Enterprises, LLC. We contribute to resiliancy of blockchain and DePIN networks through node hosting, and we publish a shamelessly Americentric blog.</p>
                <div className="d-flex justify-content-center">
                    <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" aria-label="Twitter"><TwitterIcon className="social-icon me-4" /></a>
                    <a href="https://steemit.com" target="_blank" rel="noopener noreferrer" aria-label="Steemit"><SteemitIcon className="social-icon me-4" /></a>
                    <a href="https://medium.com" target="_blank" rel="noopener noreferrer" aria-label="Medium"><MediumIcon className="social-icon me-4" /></a>
                    <a href="https://hive.io" target="_blank" rel="noopener noreferrer" aria-label="Hive"><HiveIcon className="social-icon me-4" /></a>
                </div>
            </Col>

            <Col md={3} xs={6} className="mt-md-0 my-3 text-start order-3">
                <h5>Exchange partners</h5>
                <ul className="list-unstyled">
                    <li><a href="https://www.coinbase.com/affiliates" target="_blank" rel="noopener noreferrer">Coinbase</a></li>
                    <li><a href="https://crypto.com/us/affiliate" target="_blank" rel="noopener noreferrer">Crypto.com</a></li>
                    <li><a href="https://www.gemini.com/affiliate-program" target="_blank" rel="noopener noreferrer">Gemini</a></li>
                    <li><a href="https://support.kraken.com/hc/en-us/articles/360027545252-Kraken-Affiliate-program" target="_blank" rel="noopener noreferrer">Kraken</a></li>
                    <li><a href="https://uphold.com/en-us/products/affiliate" target="_blank" rel="noopener noreferrer">Uphold</a></li>
                </ul>
            </Col>
        </Row>
    </Container>

    <div className="footer-copyright text-center py-3">
        Copyright © {new Date().getFullYear()} Corya Enterprises, LLC
    </div>

  </footer>

export default Footer
