import * as React from "react"
import PropTypes from "prop-types"
import Container from 'react-bootstrap/Container'

import Navbar from "./navbar"
import Footer from "./footer"
import "./layout.scss"

const Layout = ({ children }) => {

  return (
    <React.Fragment>
      <Navbar />
        <main>
          {children}
        </main>
      <Footer/>
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
